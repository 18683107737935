import logo from './logo.svg';

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import React from 'react';

import { Map, GoogleApiWrapper, google, Marker } from 'google-maps-react';

import bootstrap from 'bootstrap'

const mapStyles = {
  width: '100%',
  height: '100%',
};

const RETURN_CODE_DROP_NOT_IN_ROUTE = 0;
const RETURN_CODE_ROUTE_NOT_YET_STARTED = 1;
const RETURN_CODE_ACTIVE_ROUTE_NO_DRIVER_TRACKING = 2;
const RETURN_CODE_SOMEONE_BEFORE_DROP = 3;
const RETURN_CODE_DROP_ARE_NEXT = 6;
const RETURN_CODE_DROP_SUCCESSFUL = 7;
const RETURN_CODE_DROP_FAILED = 8;
const RETURN_CODE_DROP_SKIPPED_ROUTE_ACTIVE = 9;
const RETURN_CODE_ENDED_ROUTE_DROP_WERE_SKIPPED = 10;
const RETURN_CODE_BAD_TRACKING_IDENTIFIER = 20;
const RETURN_CODE_NOT_ALL_DATA_IS_READY = 30;

const COMPANY_TYPE_DELIVERY = "DELIVERY"
const COMPANY_TYPE_SERVICE = "SERVICE"


class App extends React.Component {

    lang_da = {
        shorthand : "da",
        we_are_having_trouble_contacting_the_server : "Vi har problemer med at kontakte serveren",
        fetching_the_data : "Henter data",
        delivery_could_not_be_found : "Leveringen kunne ikke findes",
        the_driver_have_not_started_the_route_yet : "Chaufføren har endnu ikke startet sin rute",
        we_are_missing_some_deliveries_before_yours : "Vi mangler at udføre nogle leveringer før dig",
        we_are_on_our_way : "Vi er på vej",
        your_delivery_is_the_next : "Din levering er den næste",
        delivery_completed : "Leveringen er udført",
        we_could_not_complete_the_delivery : "Vi kunne desværre ikke udføre leveringen",
        the_task_could_not_be_found : "Opgaven kunne ikke findes",
        our_service_technician_have_not_started_the_route_yet : "Vores servicetekniker har endnu ikke startet sin rute",
        we_are_missing_some_tasks_before_yours : "Vi mangler at udføre nogle opgaver før dig",
        your_service_is_the_next : "Din service er den næste",
        service_completed : "Service er udført",
        we_could_not_complete_the_service : "Vi kunne desværre ikke udføre service",
        delivery_singular : "levering",
        delivery_plural : "leveringer",
        task_singular : "opgave",
        task_plural : "opgaver",
        we_are_missing_x_y_before_your : "Vi mangler at udføre {0} {1} før dig",
        at : "kl",
        in_the_time_interval : "i tidsintervallet",
        we_are_expecting_to_arrive : "Vi forventer at ankomme til {0} {1} {2}",
        we_are_expecting_to_visit_you_but_dont_know_when : "Vi forventer stadigvæk at besøge dig, men vi kan ikke estimere hvornår",
        delivery_happened_at : "Leveringen til {0} skete kl {1}",
        we_tried_the_delivery_at : "Vi forsøgte at udføre leveringen kl {0}",
        service_happend_at : "Service til {0} blev færdiggjort kl {1}",
        we_tried_the_service_at : "Vi forsøgte at udføre service kl {0}",
        diroto_description : "Diroto ruteoptimering, ruteplanlægning og flådestyring",
        contact : "Kontakt",
        customer_service : "kundeservice",
    }

    lang_sv = {
        shorthand : "sv",
        we_are_having_trouble_contacting_the_server : "Vi har problem med att kontakta servern",
        fetching_the_data : "Hämtar data",
        delivery_could_not_be_found : "Leveransen kunde inte hittas",
        the_driver_have_not_started_the_route_yet : "Föraren har ännu inte påbörjat sin rutt",
        we_are_missing_some_deliveries_before_yours : "Vi måste göra några leveranser innan vi kommer till dig",
        we_are_on_our_way : "Vi är på väg",
        your_delivery_is_the_next : "Din leverans är näst i kön",
        delivery_completed : "Leverans utförd",
        we_could_not_complete_the_delivery : "Vi kunde tyvärr inte leverera",
        the_task_could_not_be_found : "Uppdraget kunde inte hittas",
        our_service_technician_have_not_started_the_route_yet : "Vår servicetekniker har ännu inte påbörjat sin rutt",
        we_are_missing_some_tasks_before_yours : "Vi har några uppdrag att slutföra innan vi kommer till dig",
        your_service_is_the_next : "Din service är näst i kön",
        service_completed : "Service utförd",
        we_could_not_complete_the_service : "Vi kunde tyvärr inte utföra någon service",
        delivery_singular : "leverans",
        delivery_plural : "leveranser",
        task_singular : "uppdrag",
        task_plural : "uppdrag",
        we_are_missing_x_y_before_your : "Vi måste utföra {0} {1} före dig",
        at : "kl",
        in_the_time_interval : "i tidsintervallet",
        we_are_expecting_to_arrive : "Vi förväntar oss att vara hos dig {0} {1} {2}",
        we_are_expecting_to_visit_you_but_dont_know_when : "Vi räknar fortfarande med att besöka dig, men vi kan inte uppskatta när",
        delivery_happened_at : "Leveransen till {0} skedde kl {1}",
        we_tried_the_delivery_at : "Vi försökte leverera kl {0}",
        service_happend_at : "Servicen för {0} utfördes kl {1}",
        we_tried_the_service_at : "Vi försökte utföra service kl {0}",
        diroto_description : "Diroto ruttoptimering, ruttplanering och flotthantering",
        contact : "Kontakta",
        customer_service : "kundtjänst",
    }

    constructor(props) {
        super(props);
        this.map = React.createRef();
        this.state = {
           center: {lat: 55.6397139, lng: 10.8999305},
           zoom: 7,
           streetViewControl: false,
           mapTypeControl: false,
           fullscreenControl: false,
           zoomControlOptions: {
             position: 7
           },
           last_response: null,
           server_error: false,
           home_icon: {
                url: "home-diroto.png",
                anchor: new this.props.google.maps.Point(24, 48),
                scaledSize: new this.props.google.maps.Size(48, 48),
           },
           delivery_attempt_icon: {
                url: "box-diroto.png",
                anchor: new this.props.google.maps.Point(24, 48),
                scaledSize: new this.props.google.maps.Size(48, 48),
           },
           driver_icon: {
                url: "van-diroto.png",
                anchor: new this.props.google.maps.Point(24, 24),
                scaledSize: new this.props.google.maps.Size(48, 48),
           },
           home_marker_position: null,
           delivery_attempt_marker_position: null,
           driver_marker_position: null,
           has_interacted_with_the_map: false,
           lang: window.navigator.language.toLowerCase().startsWith("sv") ? this.lang_sv : this.lang_da,
        }
        this.get_callable_contact_number = this.get_callable_contact_number.bind(this);
        this.get_status_text_line = this.get_status_text_line.bind(this);
        this.get_customers_left_before_this_drop_status = this.get_customers_left_before_this_drop_status.bind(this);
        this.get_drop_info_status = this.get_drop_info_status.bind(this);
        this.get_delivery_info_status = this.get_delivery_info_status.bind(this);
        this.format_timestamp = this.format_timestamp.bind(this);
        this.zero_pad_string = this.zero_pad_string.bind(this);
        this.get_bounds = this.get_bounds.bind(this);
        this.compare_position = this.compare_position.bind(this);
        this.is_valid_position = this.is_valid_position.bind(this);
        this.mapInteraction = this.mapInteraction.bind(this);
        this.select_language = this.select_language.bind(this);
    }

    componentDidMount() {

        String.prototype.format = function() {
          var args = arguments;
          return this.replace(/{(\d+)}/g, function(match, number) { 
            return typeof args[number] != 'undefined'
              ? args[number]
              : match
            ;
          });
        };

        let id;
        const url_parts = window.location.href.split("/");
        let get_variables = url_parts[url_parts.length - 1];
        get_variables = "tracking_identifier=" + get_variables;

        const update_status = () => {
            fetch(process.env.REACT_APP_CLOUD_FUNCTION + "?" + get_variables)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        last_response: result,
                        server_error: false,
                    });
                    if (!this.compare_position(this.state.last_response?.drop_info?.position, this.state.home_marker_position)) {
                        this.setState({
                            home_marker_position: this.state.last_response?.drop_info?.position,
                        });
                    }
                    if (!this.compare_position(this.state.last_response?.delivery_info?.position, this.state.delivery_attempt_marker_position)) {
                        this.setState({
                            delivery_attempt_marker_position: this.state.last_response?.delivery_info?.position,
                        });
                    }
                    if (!this.compare_position(this.state.last_response?.driver?.position, this.state.driver_marker_position)) {
                        this.setState({
                            driver_marker_position: this.state.last_response?.driver?.position,
                        });
                    }
                    if (!this.state.has_interacted_with_the_map && this.get_bounds()) {
                        if (this.map.current) {
                            this.map.current.map.fitBounds(this.get_bounds());
                        }
                    }
                },
                (error) => {
                    this.setState({
                      server_error: true,
                    });
                },
            );
        };
        id = setInterval(update_status, 10000);
        update_status();
    }

    select_language(l) {
        if (l === "sv") {
            this.setState({
                lang : this.lang_sv,
            });
        } else {
            this.setState({
                lang : this.lang_da,
            });
        }
    }

    compare_position(p1, p2) {
        if (!p1 || !p2) {
            return false;
        }
        return p1.lat == p2.lat && p1.lng == p2.lng
    }

    is_valid_position(p) {
        if (!p) {
            return false;
        }
        return !(p.lat == 0 && p.lng == 0)
    }

    zero_pad_string(s, p) {
        return s.toString().padStart(p, '0');
    }

    format_timestamp(timestamp1, timestamp2=0) {
        const current_datetime = new Date();
        const datetime1 = new Date(timestamp1 * 1000);
        const datetime2 = new Date(timestamp2 * 1000);

        const time_string_1 = this.zero_pad_string(datetime1.getHours(), 2) + ":" + this.zero_pad_string(datetime1.getMinutes(), 2);
        const date_string_1 = this.zero_pad_string(datetime1.getDate(), 2) + "/" + this.zero_pad_string(datetime1.getMonth()+1, 2);

        const time_string_2 = this.zero_pad_string(datetime2.getHours(), 2) + ":" + this.zero_pad_string(datetime2.getMinutes(), 2);
        const date_string_2 = this.zero_pad_string(datetime2.getDate(), 2) + "/" + this.zero_pad_string(datetime2.getMonth()+1, 2);

        function is_same_date(datetime1, datetime2) {
            return datetime1.getMonth() == datetime2.getMonth() && datetime1.getDate() == datetime2.getDate() && datetime1.getYear() == datetime2.getYear();
        }

        function is_same_time(datetime1, datetime2) {
            return datetime1.getMinutes() == datetime2.getMinutes() && datetime1.getHours() == datetime2.getHours();
        }

        function is_today(datetime) {
            return is_same_date(datetime, current_datetime);
        }

        function is_in_future(datetime) {
            return current_datetime.getTime() <= datetime.getTime();
        }

        if (timestamp2 == 0) {
            if (is_today(datetime1)) {
                return {type: "point", text: time_string_1, is_in_future: is_in_future(datetime1)};
            } else {
                return {type: "point", text: time_string_1 + " " + date_string_1, is_in_future: is_in_future(datetime1)};
            }
        } else {
            if (is_same_date(datetime1, datetime2)) {
                if (is_today(datetime1)) {
                    if (is_same_time(datetime1, datetime2)) {
                        return {type: "point", text: time_string_1, is_in_future: is_in_future(datetime2)};
                    } else {
                        return {type: "interval", text: time_string_1 + " - " + time_string_2, is_in_future: is_in_future(datetime2)};
                    }
                } else {
                    if (is_same_time(datetime1, datetime2)) {
                        return {type: "point", text: time_string_1 + " " + date_string_1, is_in_future: is_in_future(datetime2)};
                    } else {
                        return {type: "interval", text: time_string_1 + " - " + time_string_2 + " " + date_string_1, is_in_future: is_in_future(datetime2)};
                    }
                }
            } else {
                return {type: "interval", text: time_string_1 + " " + date_string_1 + " - " + time_string_2 + " " + date_string_2, is_in_future: is_in_future(datetime2)};
            }
        }
    }

    get_callable_contact_number() {
        if (this.state.last_response.company_info.contact_number) {
            return ("tel:" + this.state.last_response.company_info.contact_number);
        } else {
            return "";
        }
    }

    get_status_text_line() {
        if (this.state.server_error) {
            return this.state.lang.we_are_having_trouble_contacting_the_server;
        } else if (!this.state.last_response) {
            return this.state.lang.fetching_the_data;
        }
        if (!this.state.last_response.company_info || this.state.last_response.company_info.company_type == COMPANY_TYPE_DELIVERY) {
            switch(this.state.last_response.code) {
                case RETURN_CODE_DROP_NOT_IN_ROUTE:
                case RETURN_CODE_BAD_TRACKING_IDENTIFIER:
                case RETURN_CODE_NOT_ALL_DATA_IS_READY:
                    return this.state.lang.delivery_could_not_be_found
                case RETURN_CODE_ROUTE_NOT_YET_STARTED:
                    return this.state.lang.the_driver_have_not_started_the_route_yet
                case RETURN_CODE_SOMEONE_BEFORE_DROP:
                    return this.state.lang.we_are_missing_some_deliveries_before_yours
                case RETURN_CODE_ACTIVE_ROUTE_NO_DRIVER_TRACKING:
                case RETURN_CODE_DROP_SKIPPED_ROUTE_ACTIVE:
                    return this.state.lang.we_are_on_our_way
                case RETURN_CODE_DROP_ARE_NEXT:
                    return this.state.lang.your_delivery_is_the_next
                case RETURN_CODE_DROP_SUCCESSFUL:
                    return this.state.langdelivery_completed
                case RETURN_CODE_DROP_FAILED:
                case RETURN_CODE_ENDED_ROUTE_DROP_WERE_SKIPPED:
                    return this.state.lang.we_could_not_complete_the_delivery
            }
        } else {
            switch(this.state.last_response.code) {
                case RETURN_CODE_DROP_NOT_IN_ROUTE:
                case RETURN_CODE_BAD_TRACKING_IDENTIFIER:
                case RETURN_CODE_NOT_ALL_DATA_IS_READY:
                    return this.state.lang.the_task_could_not_be_found
                case RETURN_CODE_ROUTE_NOT_YET_STARTED:
                    return this.state.lang.our_service_technician_have_not_started_the_route_yet
                case RETURN_CODE_SOMEONE_BEFORE_DROP:
                    return this.state.lang.we_are_missing_some_tasks_before_yours
                case RETURN_CODE_ACTIVE_ROUTE_NO_DRIVER_TRACKING:
                case RETURN_CODE_DROP_SKIPPED_ROUTE_ACTIVE:
                    return this.state.lang.we_are_on_our_way
                case RETURN_CODE_DROP_ARE_NEXT:
                    return this.state.lang.your_service_is_the_next
                case RETURN_CODE_DROP_SUCCESSFUL:
                    return this.state.lang.service_completed
                case RETURN_CODE_DROP_FAILED:
                case RETURN_CODE_ENDED_ROUTE_DROP_WERE_SKIPPED:
                    return this.state.lang.we_could_not_complete_the_service
            }
        }
    }

    get_customers_left_before_this_drop_status() {
        if (!this.state?.last_response) {
            return null;
        }
        let customers_left_before_this_drop = this.state.last_response?.route_info?.customers_left_before_this_drop;
        switch(this.state.last_response.code) {
            case RETURN_CODE_DROP_NOT_IN_ROUTE:
            case RETURN_CODE_BAD_TRACKING_IDENTIFIER:
            case RETURN_CODE_NOT_ALL_DATA_IS_READY:
            case RETURN_CODE_ROUTE_NOT_YET_STARTED:
            case RETURN_CODE_DROP_SKIPPED_ROUTE_ACTIVE:
            case RETURN_CODE_DROP_ARE_NEXT:
            case RETURN_CODE_DROP_SUCCESSFUL:
            case RETURN_CODE_DROP_FAILED:
            case RETURN_CODE_ENDED_ROUTE_DROP_WERE_SKIPPED:
                return null;
            case RETURN_CODE_SOMEONE_BEFORE_DROP:
            case RETURN_CODE_ACTIVE_ROUTE_NO_DRIVER_TRACKING:
                if (customers_left_before_this_drop) {
                    let type = ""
                    if (!this.state.last_response.company_info || this.state.last_response.company_info.company_type == COMPANY_TYPE_DELIVERY) {
                        type = customers_left_before_this_drop == 1 ? this.state.lang.delivery_singular : this.state.lang.delivery_plural;
                    } else {
                        type = customers_left_before_this_drop == 1 ? this.state.lang.task_singular : this.state.lang.task_plural;
                    }
                    return this.state.lang.we_are_missing_x_y_before_your.format(customers_left_before_this_drop, type);
                } else {
                    return null;
                }
        }
    }

    get_drop_info_status() {
        if (!this.state?.last_response?.drop_info) {
            return null;
        }
        switch(this.state.last_response.code) {
            case RETURN_CODE_DROP_NOT_IN_ROUTE:
            case RETURN_CODE_BAD_TRACKING_IDENTIFIER:
            case RETURN_CODE_NOT_ALL_DATA_IS_READY:
            case RETURN_CODE_DROP_FAILED:
            case RETURN_CODE_DROP_SUCCESSFUL:
            case RETURN_CODE_ENDED_ROUTE_DROP_WERE_SKIPPED:
                return null;
            case RETURN_CODE_ROUTE_NOT_YET_STARTED:
            case RETURN_CODE_DROP_SKIPPED_ROUTE_ACTIVE:
            case RETURN_CODE_DROP_ARE_NEXT:
            case RETURN_CODE_SOMEONE_BEFORE_DROP:
            case RETURN_CODE_ACTIVE_ROUTE_NO_DRIVER_TRACKING:
                const datetime_format_data = this.format_timestamp(this.state.last_response.drop_info.planned_arrival_time_start, this.state.last_response.drop_info.planned_arrival_time_end);
                let time_quantifier = datetime_format_data.type == "point" ? this.state.lang.at : this.state.lang.in_the_time_interval;
                if (datetime_format_data.is_in_future) {
                    return this.state.lang.we_are_expecting_to_arrive.format(this.state.last_response.drop_info.address, time_quantifier, datetime_format_data.text);
                } else {
                    return this.state.lang.we_are_expecting_to_visit_you_but_dont_know_when;
                }
        }
    }

    get_delivery_info_status() {
        if (!this.state?.last_response?.delivery_info) {
            return null;
        }
        if (!this.state.last_response.company_info || this.state.last_response.company_info.company_type == COMPANY_TYPE_DELIVERY) {
            switch(this.state.last_response.code) {
                case RETURN_CODE_DROP_NOT_IN_ROUTE:
                case RETURN_CODE_BAD_TRACKING_IDENTIFIER:
                case RETURN_CODE_NOT_ALL_DATA_IS_READY:
                case RETURN_CODE_ENDED_ROUTE_DROP_WERE_SKIPPED:
                case RETURN_CODE_ROUTE_NOT_YET_STARTED:
                case RETURN_CODE_DROP_SKIPPED_ROUTE_ACTIVE:
                case RETURN_CODE_DROP_ARE_NEXT:
                case RETURN_CODE_SOMEONE_BEFORE_DROP:
                case RETURN_CODE_ACTIVE_ROUTE_NO_DRIVER_TRACKING:
                    return null;
                case RETURN_CODE_DROP_SUCCESSFUL:
                    return this.state.lang.delivery_happened_at.format(this.state.last_response.drop_info.address, this.format_timestamp(this.state.last_response.delivery_info.time).text);
                case RETURN_CODE_DROP_FAILED:
                    return this.state.lang.we_tried_the_delivery_at.format(this.format_timestamp(this.state.last_response.delivery_info.time).text);
            }
        } else {
            switch(this.state.last_response.code) {
                case RETURN_CODE_DROP_NOT_IN_ROUTE:
                case RETURN_CODE_BAD_TRACKING_IDENTIFIER:
                case RETURN_CODE_NOT_ALL_DATA_IS_READY:
                case RETURN_CODE_ENDED_ROUTE_DROP_WERE_SKIPPED:
                case RETURN_CODE_ROUTE_NOT_YET_STARTED:
                case RETURN_CODE_DROP_SKIPPED_ROUTE_ACTIVE:
                case RETURN_CODE_DROP_ARE_NEXT:
                case RETURN_CODE_SOMEONE_BEFORE_DROP:
                case RETURN_CODE_ACTIVE_ROUTE_NO_DRIVER_TRACKING:
                    return null;
                case RETURN_CODE_DROP_SUCCESSFUL:
                    return this.state.lang.service_happend_at.format(this.state.last_response.drop_info.address, this.format_timestamp(this.state.last_response.delivery_info.time).text);
                case RETURN_CODE_DROP_FAILED:
                    return this.state.lang.we_tried_the_service_at.format(this.format_timestamp(this.state.last_response.delivery_info.time).text);
            }
        }
    }

    mapInteraction() {
        this.setState({
            has_interacted_with_the_map: true,
        });
    }

    get_bounds() {
        const bounds = new this.props.google.maps.LatLngBounds();
        let extensions = 0;
        if (this.is_valid_position(this.state.home_marker_position)) {
            bounds.extend(this.state.home_marker_position);
            extensions += 1;
        }
        if (this.is_valid_position(this.state.delivery_attempt_marker_position)) {
            bounds.extend(this.state.delivery_attempt_marker_position);
            extensions += 1;
        }
        if (this.is_valid_position(this.state.driver_marker_position)) {
            bounds.extend(this.state.driver_marker_position);
            extensions += 1;
        }
        if (extensions > 1) {
            // needs at-least 2 points to define a bound

            // Add dummy point to make sure the bottom box does not cover any markers
            let dummy_lat = bounds.getSouthWest().lat() - (bounds.getNorthEast().lat() - bounds.getSouthWest().lat()) / 1.4;
            let dummy_lng = (bounds.getNorthEast().lng() + bounds.getSouthWest().lng()) / 2;

            bounds.extend({lat: dummy_lat, lng: dummy_lng});

            return bounds;
        } else if (extensions == 1) {
            // Add dummy point to get it to calculate a zoom
            // https://gis.stackexchange.com/a/2964
            const dummy_lat_1 = bounds.getSouthWest().lat() - 1 / 111111 * 500; // 500 meter below
            const dummy_lat_2 = bounds.getSouthWest().lat() + 1 / 111111 * 250; // 250 meter above
            const dummy_lng = bounds.getNorthEast().lng();

            bounds.extend({lat: dummy_lat_1, lng: dummy_lng});
            bounds.extend({lat: dummy_lat_2, lng: dummy_lng});

            return bounds;
        } else {
            // no point to zoom to
            return null;
        }
    }

    render() {
        return (
          <div id="wrapper" className="d-flex flex-column vh-100">
              <nav className="navbar navbar-dark flex-0 text-center bg-dark">
                <img className="navbar-brand navbar-logo m-auto" src="diroto-white.svg"/>
              </nav>
              <div className="flex-grow-1 flex-shrink-1 h-100 position-relative">
                  <Map
                    ref={this.map}
                    google={this.props.google}
                    zoom={this.state.zoom}
                    style={mapStyles}
                    streetViewControl={this.state.streetViewControl}
                    mapTypeControl={this.state.mapTypeControl}
                    fullscreenControl={this.state.fullscreenControl}
                    initialCenter={this.state.center}
                    zoomControlOptions={this.state.zoomControlOptions}
                    onClick={this.mapInteraction}
                    onDragstart={this.mapInteraction}
                  >
                    {this.is_valid_position(this.state.home_marker_position)? <Marker position={this.state.home_marker_position} icon={this.state.home_icon}/> : null}
                    {this.is_valid_position(this.state.delivery_attempt_marker_position) ? <Marker position={this.state.delivery_attempt_marker_position} icon={this.state.delivery_attempt_icon}/> : null}
                    {this.is_valid_position(this.state.driver_marker_position) ? <Marker position={this.state.driver_marker_position} icon={this.state.driver_icon}/> : null}
                  </Map>
              </div>
              <footer className="flex-0 text-center p-2 text-muted">
                Copyright &copy;2022 All rights reserved | {this.state.lang.diroto_description}
              </footer>
              <div id="over-map-box">
                <div className="container">
                   <div className="row justify-content-md-center">
                     <div className="col col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6">
                        <div className="card capture-pointer-events shadow">
                          <div className="card-body">
                            <div className="float-right-top">
                              <img className={"flag " + (this.state.lang.shorthand === "sv" ? "flag-selected" : "flag-not-selected")} src="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/0.8.2/flags/4x3/se.svg" alt="Svenska" width="24" height="19" onClick={(e) => this.select_language('sv')}></img>
                              <img className={"flag " + (this.state.lang.shorthand === "da" ? "flag-selected" : "flag-not-selected")} src="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/0.8.2/flags/4x3/dk.svg" alt="Dansk" width="24" height="19" onClick={(e) => this.select_language('da')}></img>
                            </div>
                            <h5 className="card-title">{this.get_status_text_line()}</h5>
                            <h6 className="card-subtitle mb-2 text-muted">{this.state.last_response?.company_info ? <span>{this.state.last_response.company_info.name}<br/></span> : null}</h6>
                            {this.get_customers_left_before_this_drop_status() ? <p className="card-text">{this.get_customers_left_before_this_drop_status()} </p> : null }
                            {this.get_delivery_info_status() ? <p className="card-text">{this.get_delivery_info_status()} </p> : null }
                            {this.get_drop_info_status() ? <p className="card-text">{this.get_drop_info_status()} </p> : null }
                            {this.state.last_response?.company_info ? <div><a href={this.get_callable_contact_number()} className="btn btn-primary text-nowrap btn-block contact-button" role="button"><i className="fas fa-phone-alt"></i>&nbsp;{this.state.lang.contact}&nbsp;{this.state.lang.customer_service} <small>{this.state.last_response.company_info.contact_number}</small></a></div> : null }
                          </div>
                        </div>
                     </div>
                  </div>
                </div>
              </div>
           </div>
        );
    }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyClyQ9Gh4xcWbTi6pTis62pi7ITmW86XoI'
})(App);